.root {
  position: relative;
  width: 100%;
  height: 12px;
}

.outer {
  background-color: var(--global-color-white);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 2px;
  overflow: hidden;
  box-shadow: 0px 4px 2px 0px #00000012;
}

.inner {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.progress {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    #f6a9dc,
    #e7cfdf,
    #f6a9dc
  ); /* Adjust colors as needed */
  animation: wave 2s infinite linear;
  background-size: 50% 100%;
}

@keyframes wave {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
