:root {
  --global-color-1: #acdeff;
  --global-color-1-dark: #83c0e8;

  --global-color-2: #ffde9d;
  --global-color-2-dark: #fbb731;

  --global-color-3: #c8bbfa;
  --global-color-3-dark: #957fe8;

  --global-color-4: #f6a9dc;
  --global-color-4-dark: #f369c5;

  --global-color-black: #212121;
  --global-color-white: #ffffff;

  --global-font-family: "Red Rose", serif;
  --global-font-weight-light: 300;
  --global-font-weight-regular: 400;
  --global-font-weight-bold: 700;

  --app-max-width: 480px;
}

.appContainer {
  width: 100%;
  height: 100dvh;
  /* Using dynamic viewport height for better mobile support */
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  position: fixed;
  /* Prevent body scrolling */
  top: 0;
  left: 0;
}

.root {
  width: 100%;
  max-width: var(--app-max-width);
  height: 100%;
  position: relative;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* Prevent double scrollbars */
}

/* For devices smaller than max-width, take up full width */
@media (max-width: 480px) {
  .root {
    box-shadow: none;
  }
}

* {
  box-sizing: border-box;
  font-family: var(--global-font-family);
  font-weight: var(--global-font-weight-regular);
  font-optical-sizing: auto;
  font-style: normal;
}
