.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  justify-content: space-between;
}

.pagesContainer {
  touch-action: none;
  position: relative;
  padding: 0 3%;
  flex-grow: 1;
  margin-top: 6px;
  margin-bottom: 14px;
}
