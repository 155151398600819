.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    0deg,
    white 0%,
    var(--global-color-2-rgb) 50%,
    white 100%
  );
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-top: 80px;
  /* Space for the header */
}

.content h1 {
  font-size: 24px;
  font-weight: var(--global-font-weight-bold);
  margin-bottom: 8px;
  color: var(--global-color-black);
}

.content h2 {
  font-size: 18px;
  font-weight: var(--global-font-weight-bold);
  margin: 24px 0 12px;
  color: var(--global-color-black);
}

.content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
  color: var(--global-color-black);
}

.content ul {
  margin-bottom: 16px;
  padding-left: 20px;
}

.content li {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
  color: var(--global-color-black);
}

.content section {
  margin-bottom: 32px;
}

@media (max-width: 480px) {
  .content {
    padding: 16px;
    padding-top: 64px;
  }
}
