.root {
  background-color: var(--global-color-1);
  mask-image: url("@assets/footermenu-background.svg");
  mask-repeat: no-repeat;
  mask-size: cover;

  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 16px;
}

.fixedBottom {
  position: absolute;
  bottom: 0;
}

.controlButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 12px;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.controlButton:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.15);
}

/* Play button specific animations */
.controlButton:not(.playing) {
  animation: pulse 2s infinite;
}

.controlButton:not(.playing) svg circle {
  animation: bgColorPulse 2s infinite;
}

.controlButton:not(.playing):hover,
.controlButton:not(.playing):focus,
.controlButton:not(.playing):focus-visible {
  animation: none;
  transform: scale(1.1);
}

.controlButton:not(.playing):hover svg circle,
.controlButton:not(.playing):focus svg circle,
.controlButton:not(.playing):focus-visible svg circle {
  animation: none;
  fill: #212121;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes bgColorPulse {
  0% {
    fill: rgba(33, 33, 33, 1);
  }

  70% {
    fill: rgb(175, 152, 107);
  }

  100% {
    fill: rgba(33, 33, 33, 1);
  }
}

/* Pause button specific styles */
.controlButton.playing {
  transform: scale(1);
  box-shadow: none;
}

.controlButton.playing:hover {
  transform: scale(1.1);
}

.controlButton.playing svg circle {
  fill: #212121;
}
