.root {
  display: flex;
  margin: 0 var(--page-padding);
  align-items: center;
}

.title {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--global-font-weight-bold);
  text-align: left;
}

.icon {
  margin-left: 10px;
}
