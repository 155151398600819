.pageContainer {
  touch-action: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.page {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.09);
}
