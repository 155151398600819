.thumbnail {
  position: relative;
  display: flex;
  flex: 0 0 16%;
  width: 100%;
  height: 100%;
  aspect-ratio: 1.05;
  background-color: var(--global-color-white);
  mask-image: url("@assets/blob.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  justify-content: center;
  align-items: center;
}

.thumbnail svg {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root-color-1 path {
  fill: var(--global-color-1-dark);
}

.root-color-2 path {
  fill: var(--global-color-2-dark);
}

.root-color-3 path {
  fill: var(--global-color-3-dark);
}

.root-color-4 path {
  fill: var(--global-color-4-dark);
}
