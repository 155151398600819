.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.mainContainer {
  height: 100%;
  padding: 26% 5% 5% 5%;
  display: flex;
  flex-direction: column;
  gap: 80px;
  overflow-y: scroll;
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.thumbnailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("@assets/stars.svg");
  background-size: contain;
}

.paymentButtons {
  width: 100%;
}

.content {
  padding: 0 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.package {
  font-size: 22px;
  font-weight: var(--global-font-weight-bold);
  line-height: 27px;
  color: var(--global-color-black);
  text-align: center;
}

.message {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.message h2 {
  font-size: 24px;
  font-weight: var(--global-font-weight-bold);
  color: var(--global-color-black);
  margin: 0;
}

.message p {
  font-size: 16px;
  color: var(--global-color-gray);
  margin: 0;
  line-height: 1.5;
}

.actionButton {
  margin-top: 8px;
  max-width: 300px;
}
