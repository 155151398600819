.root {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.list {
  padding-top: 80px;
  padding-bottom: 100px;
}

.list::-webkit-scrollbar {
  display: none;
}

.emptyStateRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 20px;
  height: 100%;
  padding: 0 6%;
}

.createButton {
  margin-top: 15px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.title {
  text-align: center;
  font-weight: var(--global-font-weight-bold);
  font-size: 22px;
  animation: fadeIn 0.8s ease-out forwards;
  opacity: 0;
}

.text {
  text-align: center;
  font-weight: var(--global-font-weight-normal);
  animation: fadeIn 0.8s ease-out 0.3s forwards;
  opacity: 0;
  display: inline-block;
  white-space: pre-wrap;
  overflow: hidden;
}
