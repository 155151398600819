.root {
  width: 100%;
  padding: 16px;
  background-color: white;
  border-top: 1px solid rgba(var(--global-color-1-rgb), 0.1);
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

.link {
  background: none;
  border: none;
  color: rgb(80, 76, 76);
  font-size: 14px;
  cursor: pointer;
  padding: 4px 8px;
}

.link:hover {
  opacity: 0.8;
}
