.root {
  display: flex;
  flex-direction: row;
  overflow-x: visible;
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 8px 20px 100px 20px;
  gap: 0px;
  margin-bottom: -100px;
}

.root::-webkit-scrollbar {
  display: none;
}

.rotateRight {
  transform: rotate3d(0.1, -0.466, 0, 15deg) rotate(4.66deg);
  box-shadow:
    1em 2em 3em -2em rgba(0, 0, 0, 0.5),
    1em 2em 3.5em -2.5em rgba(0, 0, 0, 0.5);
}

.rotateLeft {
  transform: rotate3d(-0.1, 0.466, -0, 15deg) rotate(-4.66deg);
  box-shadow:
    2em 2.5em 6em -2em rgba(0, 0, 0, 0.5),
    1em 2em 3.5em -2.5em rgba(0, 0, 0, 0.5);
}
