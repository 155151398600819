.root {
  position: relative;
  height: 160px;
  width: 140px;
  min-width: 140px;
  align-items: center;
  background-color: var(--global-color-1);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 4%;
  padding: 2% 2%;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
}

.root:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 5px 0px var(--global-color-1-dark);
  z-index: -1;
  border-radius: 20px;
}

.titleContainer {
  display: flex;
  height: 36px;
  vertical-align: middle;
  align-items: center;
}

.title {
  font-weight: var(--global-font-weight-bold);
  line-height: 18px;
  font-size: 14px;
  overflow: hidden;
  color: var(--global-color-black);
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail {
  flex: 1 1;
  overflow: hidden;
  aspect-ratio: 1.05;
  background-color: var(--global-color-1-dark);
  mask-image: url("@assets/pumpkin-mask.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
}
.blob {
  width: 25px;
  height: 25px;
  display: flex;
  position: absolute;
  right: 6px;
  top: 6px;
  /* justify-content: center; */
  /* align-items: center; */

  /* background-color: var(--global-color-1-dark); */
  background-image: url("@assets/radio-selected.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.radioButton-color-1-dark path {
  stroke: var(--global-color-1-dark);
}

.radioButton-color-2-dark path {
  stroke: var(--global-color-2-dark);
}

.radioButton-color-3-dark path {
  stroke: var(--global-color-3-dark);
}

.radioButton-color-4-dark path {
  stroke: var(--global-color-4-dark);
}

.root-color-1 {
  background-color: var(--global-color-1);
  border-color: var(--global-color-1-dark);
}

.root-color-1::after {
  box-shadow: 0px 5px 0px var(--global-color-1-dark);
}

.color-1-dark {
  background-color: var(--global-color-1-dark);
}

.root-color-2 {
  background-color: var(--global-color-2);
  border-color: var(--global-color-2-dark);
}

.root-color-2::after {
  box-shadow: 0px 5px 0px var(--global-color-2-dark);
}

.color-2-dark {
  background-color: var(--global-color-2-dark);
}

.root-color-3 {
  background-color: var(--global-color-3);
  border-color: var(--global-color-3-dark);
}
.root-color-3::after {
  box-shadow: 0px 5px 0px var(--global-color-3-dark);
}

.color-3-dark {
  background-color: var(--global-color-3-dark);
}

.root-color-4 {
  background-color: var(--global-color-4);
  border-color: var(--global-color-4-dark);
}

.root-color-4::after {
  box-shadow: 0px 5px 0px var(--global-color-4-dark);
}

.color-4-dark {
  background-color: var(--global-color-4-dark);
}
