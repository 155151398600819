.root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: none;
  transition: background 0.3s ease-in;
}

.background {
  background: rgba(31, 29, 42, 0.8);
}

.modalContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 70px 4% 20px 4%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  background-image: url("@assets/modal-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.title {
  font-size: 22px;
  font-weight: var(--global-font-weight-bold);
}

.text {
  font-size: 16px;
  font-weight: var(--global-font-weight-normal);
  text-align: center;
}

.closed {
  transform: translateY(100%);
  transition: transform 0.3s ease-in;
}

.open {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}
