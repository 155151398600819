.root {
  font-size: 16px;
  font-weight: var(--global-font-weight-bold);
  margin: 0 auto;
  text-transform: uppercase;
  line-height: 20px;
  padding: 20px 30px;
  background-image: url("@assets/button.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition:
    transform 0.2s ease-in-out,
    filter 0.2s ease-in-out;
}

.fullWidth {
  width: 100%;
}

.disabled {
  opacity: 0.4;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }

  50% {
    transform: scale(1.03);
    filter: brightness(1.03);
  }

  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

.animated {
  animation: pulse 2s infinite;
}
