.root {
  width: 100%;
  padding: 4% 4% 2% 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.withBackground {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  padding-bottom: 6%;
}

.fixed {
  position: absolute;
  top: 0;
}

.title {
  font-size: 24px;
  font-weight: var(--global-font-weight-bold);
  line-height: 30px;
}

.blob {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--global-color-1-dark);
  mask-image: url("@assets/blob.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-size: cover;
}
