.root {
  position: relative;
  --page-padding: 6%;
  display: flex;
  z-index: 1;

  height: 100%;
  width: 100%;

  flex-direction: column;
  overflow: hidden;
}

.root::-webkit-scrollbar {
  display: none;
}

.mainTitle {
  font-size: 30px;
  /* line-height: 30px; */
  color: var(--global-color-2-dark);
  font-weight: var(--global-font-weight-bold);
  margin: 0;
  padding: 0px var(--page-padding) 50px var(--page-padding);
  text-align: left;
  white-space: nowrap;

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 20%,
    transparent
  );
  z-index: 1;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  margin-top: -50px;
  padding-top: 50px;

  overflow-x: hidden;
  overflow-y: auto;
}

.sectionsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--global-font-weight-normal);
  margin: 0px var(--page-padding) 4% var(--page-padding);
  font-style: italic;
}

.carousel {
  padding-left: 20px;
  padding-right: var(--page-padding);
}

.textAreaContainer {
  padding: 0 var(--page-padding);
}

.navigationButtons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 2rem var(--page-padding);
  position: relative;
  background: linear-gradient(to top, var(--color-background) 80%, transparent);
  width: 100%;
  box-sizing: border-box;
}

.backButton {
  flex: 1;
  max-width: 200px;
  background-color: var(--color-background-secondary);
  color: var(--color-text-secondary);
  border: 1px solid var(--color-border);
}

.backButton:hover:not(:disabled) {
  background-color: var(--color-background-hover);
}

.nextButton,
.createButton {
  flex: 1;
  max-width: 50%;
}
