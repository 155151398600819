.splashPage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-out;
  overflow: hidden;
}

.splashImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.splashImage.loaded {
  opacity: 1;
}

.splashContent {
  position: relative;
  z-index: 2;
  text-align: center;
  transform: translateY(0);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.splashContent.loaded {
  opacity: 1;
}

.exit {
  opacity: 0;
  transform: scale(1.1);
}

.exit .splashContent {
  transform: translateY(-20px);
}

.splashLogo {
  margin-bottom: 2rem;
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.appName {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 1rem;
  color: #ffffff;
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.loadingIndicator {
  margin-top: 2rem;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.exit .loadingIndicator {
  opacity: 0;
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
